import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import VideoCallOutlinedIcon from "@mui/icons-material/VideoCallOutlined";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/userSlice";
import { useSelector, useDispatch } from "react-redux";
import Upload from "./Upload";
import axios from "axios";
import Amleko from "../img/amleko.png";
import { Modal } from "antd";
import Popup from "reactjs-popup";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../firebase";

const Container = styled.div`
  top: 0;
  background-color: ${({ theme }) => theme.bgLighter};
  height: 56px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0px 20px;
  position: relative;
`;

const Search = styled.div`
  width: 40%;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: ${({ theme }) => theme.text};
  @media screen and (max-width: 768px) {
    width: 30%;
  }
`;

const Input = styled.input`
  border: none;
  font-size: 10;
  background-color: transparent;
  outline: none;
  color: ${({ theme }) => theme.text};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Button = styled.button`
  padding: 5px 15px;
  background-color: #fff;
  border: 2px solid #000;
  color: #000;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const ButtonBlack = styled.button`
  padding: 5px 15px;
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.text};
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #999;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const Logo = styled.div`
  gap: 5px;
  font-weight: bold;
  width: 40%;
  position: absolute;
  left: 0px;
  right: 100px;
  bottom: 0px;
  padding-bottom: 8px;
  display: flex;
  align-items: left;
  justify-content: space-between;
`;

const Img = styled.img`
  height: 35px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #000;
`;

const Wrap = styled.div`
  padding: 5px 15px;
  background-color: #fff;

  border: 1px solid #000;
  border-radius: 5px;
  font-weight: 500;
  color: #000;
`;

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [q, setQ] = useState("");
  const { currentUser } = useSelector((state) => state.user);
  const [img, setImg] = useState("");
  const [profImg, setProfImg] = useState("");
  const [imgPerc, setImgPerc] = useState(0);
  const [updatedImg, setUpdatedImg] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchChannel = async () => {
      const res = await axios.get(`/users/find/${currentUser._id}`);
      setImg(res.data.img);
    };
    fetchChannel();
  }, [currentUser._id]);

  useEffect(() => {
    profImg && uploadFile(profImg, "imgUrl");
  }, [profImg]);

  useEffect(() => {
    if (updatedImg.length > 0) {
      console.log("Updated image link: ", updatedImg);
      var obj = {
        user: currentUser,
        img: updatedImg,
      };
      updatedProfilePic(obj);
      console.log("Updated Image: ", updatedImg);
    }
  }, [updatedImg]);

  const updatedProfilePic = async (obj) => {
    const res = await axios.put(`/users/${currentUser._id}`, obj);
    console.log("RESPONSE FROM updating image: ", res);
    return res;
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleLogout = async () => {
    setIsModalOpen(false);
    dispatch(logout());
    navigate(`signin`);
    console.log("Logging out user: ");
    const res = await axios.get(`/auth/signout`);
    console.log("Logging out user: ", res);
  };

  const uploadFile = (file, urlType) => {
    const storage = getStorage(app);
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setImgPerc(Math.round(progress));

        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log("ERROR: ", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUpdatedImg(downloadURL);
        });
      }
    );
  };

  return (
    <>
      <Container>
        <Wrapper>
          <Modal
            title={currentUser.name}
            open={isModalOpen}
            footer={[
              <Button key="back" onClick={handleLogout}>
                LogOut
              </Button>,
              <Button key="back" onClick={handleOk}>
                Cancel
              </Button>,
            ]}
          ></Modal>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <Logo>
              <Img src={Amleko} />
            </Logo>
          </Link>
          <Search>
            <Input
              placeholder="Search"
              onChange={(e) => setQ(e.target.value)}
            />
            <SearchOutlinedIcon onClick={() => navigate(`/search?q=${q}`)} />
          </Search>
          {currentUser ? (
            <User>
              {/* <VideoCallOutlinedIcon onClick={() => setOpen(true)} /> */}
              <Popup trigger={<Avatar src={img} />} position="bottom">
                <Wrap>
                  {/* <Label>New Image?</Label> */}
                  {imgPerc > 0 ? (
                    " Uploading: " + imgPerc + "% (refresh when complete)"
                  ) : (
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={(e) => setProfImg(e.target.files[0])}
                    />
                  )}
                </Wrap>
              </Popup>

              <Popup
                trigger={
                  <ButtonBlack

                  // key="back"
                  // onClick={() => {
                  //   setIsModalOpen(true);
                  // }}
                  >
                    {currentUser.name}
                  </ButtonBlack>
                }
                position="bottom"
              >
                <Button key="logout" onClick={handleLogout}>
                  Log Out
                </Button>
              </Popup>
            </User>
          ) : (
            <Link to="signin" style={{ textDecoration: "none" }}>
              <Button>
                <AccountCircleOutlinedIcon />
                SIGN IN
              </Button>
            </Link>
          )}
        </Wrapper>
      </Container>
      {/* {open && <Upload setOpen={setOpen} />} */}
    </>
  );
};

export default Navbar;
