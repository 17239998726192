import React, { useEffect, useState, useRef, createRef } from "react";
import styled from "styled-components";
import ThumbUpOutlinedIcon from "@mui/icons-material/FavoriteBorder";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/Bookmark";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/Favorite";
import Comments from "../components/Comments";
import Card from "../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { dislike, fetchSuccess, fetchStart, like } from "../redux/videoSlice";
import { format } from "timeago.js";
import { subscription } from "../redux/userSlice";
import Recommendation from "../components/Recommendation";
import DotIcon from "@mui/icons-material/Pending";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  gap: 24px;
  @media screen and (max-width: 768px) {
  }
`;

const ButtonDelete = styled.div`
  // position: absolute;
  // left: 0px;
  // right: 0px;
  display: flex;
  align-items: flex-end;
  gap: 5px;
  cursor: pointer;
`;

const Content = styled.div`
  flex: 5;
  @media screen and (max-width: 768px) {
  }
`;
const VideoWrapper = styled.div``;

const Title = styled.h1`
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

const TitleB = styled.h1`
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;

  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Info = styled.span`
  color: ${({ theme }) => theme.textSoft};
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  color: ${({ theme }) => theme.text};
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const Hr = styled.hr`
  margin: 15px 0px;
  border: 0.5px solid ${({ theme }) => theme.soft};
`;

const Channel = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChannelInfo = styled.div`
  display: flex;
  gap: 20px;
`;

const Image = styled.img`
  width: 35px;
  height: 35px;
  background-color: black;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const ChannelDetail = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
`;

const ChannelName = styled.span`
  font-weight: 500;
`;

const ChannelCounter = styled.span`
  margin-top: 5px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.textSoft};
  font-size: 12px;
`;

const Description = styled.p`
  font-size: 14px;
`;

const Subscribe = styled.button`
  background-color: #000;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 3px;
  height: max-content;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const VideoFrame = styled.video`
  max-height: 720px;
  width: 100%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const ButtonBlack = styled.button`
  padding: 5px 15px;

  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`;

function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}

function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
}

const Video = () => {
  const { currentUser } = useSelector((state) => state.user);
  // const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const { currentVideo } = useSelector((state) => state.video);
  const dispatch = useDispatch();
  // dispatch(fetchStart());

  const fullPath = useLocation();
  const path = useLocation().pathname.split("/")[2];

  const [channel, setChannel] = useState({});
  const [currVideo, setCurrVideo] = useState({});
  const dataFetchedRef = useRef(false);
  const divRef = createRef();
  const navigate = useNavigate();
  const [count, setCount] = useState(0);

  const isVisible = usePageVisibility();
  const delay = 10000;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const videoRes = await axios.get(`/videos/find/${path}`);
        const channelRes = await axios.get(
          `/users/find/${videoRes.data.userId}`
        );
        console.log("CHANNEL RESPONSE: ", channelRes.data);
        console.log(fullPath);

        setChannel(channelRes.data);
        dispatch(fetchSuccess(videoRes.data));
        setCurrVideo(videoRes.data);
        console.log(videoRes.data);
        const incrementView = await axios.put(
          `/videos/view/${videoRes.data._id}`
        );
        console.log("Increment: ", incrementView);
      } catch (err) {
        console.log("ERROR ENCOUNTERED: ", err);
      }
    };

    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchData();
  }, [path, dispatch]);

  useEffect(() => {
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      console.log("WINDOW WIDTH: ", window.innerWidth);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLike = async () => {
    console.log("Inside handle like");

    if (currentVideo.likes?.includes(currentUser?._id)) {
      console.log("disliking");
      await axios.put(`/users/dislike/${currVideo._id}`);
      dispatch(dislike(currentUser._id));
    } else {
      console.log("liking");
      await axios.put(`/users/like/${currVideo._id}`);
      dispatch(like(currentUser._id));
    }
  };
  const handleDislike = async () => {
    await axios.put(`/users/dislike/${currVideo._id}`);
    dispatch(dislike(currentUser._id));
  };

  const handleSub = async () => {
    currentUser.subscribedUsers.includes(channel._id)
      ? await axios.put(`/users/unsub/${channel._id}`)
      : await axios.put(`/users/sub/${channel._id}`);
    dispatch(subscription(channel._id));
  };

  //TODO: DELETE VIDEO FUNCTIONALITY
  const handleDelete = async () => {
    console.log("Inside delete function.");
    var res = await axios.delete(`/videos/${currVideo._id}`);
    console.log("RES for Delete: ", res);
    navigate("/");
  };

  return (
    <Container>
      {currVideo.createdAt != null ? (
        <>
          <Content>
            <VideoWrapper>
              <VideoFrame src={currVideo.videoUrl} controls />
            </VideoWrapper>
            <Title>{currVideo.title}</Title>
            <Details>
              <Info>
                {currVideo.views} views • {format(currVideo.createdAt)}
              </Info>
              <Buttons>
                <Button onClick={handleLike}>
                  {currentVideo.likes?.includes(currentUser?._id) ? (
                    <ThumbUpIcon />
                  ) : (
                    <ThumbUpOutlinedIcon />
                  )}{" "}
                  {currentVideo.likes?.length}
                </Button>
                {/* <Button onClick={handleDislike}>
                  {currentVideo.dislikes?.includes(currentUser?._id) ? (
                    <ThumbDownIcon />
                  ) : (
                    <ThumbDownOffAltOutlinedIcon />
                  )}{" "}
                  Dislike
                </Button> */}
                {/* <Button>
                  <ReplyOutlinedIcon /> Share
                </Button> */}
                {channel.name == currentUser.name ? (
                  <>
                    <Popup
                      trigger={
                        <Button>
                          <DotIcon /> Edit
                        </Button>
                      }
                      position="top"
                    >
                      <ButtonBlack key="delete" onClick={handleDelete}>
                        Delete Video
                      </ButtonBlack>
                    </Popup>
                  </>
                ) : (
                  <>
                    <Button>
                      <AddTaskOutlinedIcon /> Saved
                    </Button>
                  </>
                )}
              </Buttons>
            </Details>
            <Hr />
            <Channel>
              <ChannelInfo>
                <Image src={channel.img} />
                <ChannelDetail>
                  <ChannelName>{channel.name}</ChannelName>
                  <ChannelCounter>
                    {channel.subscribers} followers
                  </ChannelCounter>
                  <Description>{currVideo.desc}</Description>
                </ChannelDetail>
              </ChannelInfo>
              <Subscribe onClick={handleSub}>
                {currentUser.subscribedUsers?.includes(channel._id)
                  ? "Following"
                  : "Follow"}
              </Subscribe>
            </Channel>
            <Hr />
            {windowSize.width <= 1200 ? (
              <>
                <TitleB>
                  Comments <Comments videoId={currVideo._id} />
                </TitleB>
              </>
            ) : (
              <></>
            )}
            {/* <Comments videoId={currentVideo._id} /> */}
          </Content>
          {/* <Recommendation tags={currentVideo.tags} /> */}
          {windowSize.width <= 1200 ? (
            <></>
          ) : (
            <>
              <TitleB>
                Comments <Comments videoId={currVideo._id} />
              </TitleB>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Video;
