import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../firebase";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Select, Space, theme } from "antd";
import cancelIcon from "@mui/icons-material/Cancel";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 600px;
  height: 600px;
  background-color: ${({ theme }) => theme.bgLighter};
  color: ${({ theme }) => theme.text};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const Title = styled.h1`
  text-align: center;
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
`;
const Desc = styled.textarea`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
`;
const Button = styled.button`
  border-radius: 3px;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
  background-color: ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.textSoft};
`;
const Label = styled.label`
  font-size: 14px;
`;
const Upload = ({ setOpen }) => {
  const [img, setImg] = useState(undefined);
  const [video, setVideo] = useState(undefined);
  const [imgPerc, setImgPerc] = useState(0);
  const [videoPerc, setVideoPerc] = useState(0);
  const [inputs, setInputs] = useState({});
  const [tags, setTags] = useState([]);
  const [channels, setchannels] = useState([]);
  const [oldchannels, setOldchannels] = useState([
    {
      value: "Piano Class",
    },
    {
      value: "Worship Team",
    },
    {
      value: "Band Team",
    },
  ]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  function arrContainsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] == obj) {
        return true;
      }
    }

    return false;
  }

  const handleSelectChange = (value) => {
    // console.log(`selected ${value}`);
    var finalArr = [];
    var newObj = {
      value: value,
    };
    // if (oldchannels.find((e) => e.value === value)) {
    //   console.log("Matching value found");

    // }

    console.log(value);
    setInputs((prev) => {
      return { ...prev, ["channels"]: value };
    });

    // if (!arrContainsObject(newObj, oldchannels)) {
    //   console.log(arrContainsObject(newObj, oldchannels));
    //   finalArr.push(newObj);
    //   console.log("New channel added: ", finalArr);
    //   setchannels(finalArr);
    // }
  };

  const handleTags = (e) => {
    setTags(e.target.value.split(","));
  };

  const uploadFile = (file, urlType) => {
    const storage = getStorage(app);
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        urlType === "imgUrl"
          ? setImgPerc(Math.round(progress))
          : setVideoPerc(Math.round(progress));
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInputs((prev) => {
            return { ...prev, [urlType]: downloadURL };
          });
        });
      }
    );
  };

  useEffect(() => {
    video && uploadFile(video, "videoUrl");
  }, [video]);

  useEffect(() => {
    img && uploadFile(img, "imgUrl");
  }, [img]);

  const handleUpload = async (e) => {
    e.preventDefault();
    if (inputs != null && tags != null) {
      const res = await axios.post("/videos", { ...inputs, tags, channels });
      setOpen(false);
      res.status === 200 && navigate(`/video/${res.data._id}`);
    } else {
      console.log("Incomplete entry so not uploading.");
    }
  };

  return (
    <Container>
      <Wrapper>
        <Close onClick={() => setOpen(false)}> X</Close>
        <Title>Upload a New Video</Title>
        <Label>Video:</Label>
        {videoPerc > 0 ? (
          "Uploading: " + videoPerc + "%"
        ) : (
          <Input
            type="file"
            accept="video/*"
            onChange={(e) => setVideo(e.target.files[0])}
          />
        )}
        <Input
          type="text"
          placeholder="Title"
          name="title"
          onChange={handleChange}
        />
        <Desc
          placeholder="Description"
          name="desc"
          rows={7}
          onChange={handleChange}
        />
        <Input
          type="text"
          placeholder="Separate the tags with commas."
          onChange={handleTags}
        />
        {/* <Input type="text" placeholder="channel" onChance={handleTags} /> */}
        <Space
          style={{
            width: "100%",
          }}
          direction="vertical"
        >
          <Select
            mode="tags"
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Select or type a new channel"
            defaultValue={[]}
            onChange={(e) => {
              handleSelectChange(e);
            }}
            options={oldchannels}
          />
        </Space>

        <Label>Image:</Label>
        {imgPerc > 0 ? (
          "Uploading: " + imgPerc + "%"
        ) : (
          <Input
            type="file"
            accept="image/*"
            onChange={(e) => setImg(e.target.files[0])}
          />
        )}
        <Button onClick={handleUpload}>Upload</Button>
      </Wrapper>
    </Container>
  );
};

export default Upload;
