import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { loginFailure, loginStart, loginSuccess } from "../redux/userSlice";
import { auth, provider } from "../firebase";
import { signInWithPopup } from "firebase/auth";
import { async } from "@firebase/util";
import { useNavigate } from "react-router-dom";

import { Modal } from "antd";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 56px);
  color: ${({ theme }) => theme.text};
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.bgLighter};
  border: 1px solid ${({ theme }) => theme.soft};
  padding: 20px 50px;
  gap: 10px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;

const Title = styled.h1`
  font-size: 24px;
`;

const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: 300;
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  border-radius: 3px;
  font-size: 16px;
  padding: 10px;
  background-color: transparent;
  width: 100%;
  color: ${({ theme }) => theme.text};
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
`;

const Button = styled.button`
  border-radius: 3px;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  font-weight: 500;
  cursor: pointer;
  background-color: ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.textSoft};
`;

const More = styled.div`
  display: flex;
  margin-top: 20px;
  font-size: 12px;
  color: ${({ theme }) => theme.textSoft};
`;

const Links = styled.div`
  margin-left: 50px;
`;

const Link = styled.span`
  margin-left: 30px;
`;

const SignIn = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [msg, setMsg] = useState("");
  const [headr, setHeadr] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (name.length > 0 && password.length > 0) {
      dispatch(loginStart());
      try {
        const res = await axios.post("/auth/signin", { name, password });
        dispatch(loginSuccess(res.data));
        navigate("/");
      } catch (err) {
        dispatch(loginFailure());
        setMsg("Sign in did not succeed, please try again.");
        setHeadr("Error");
        showModal();
      }
    } else {
      setMsg("Please fill all fields and try again.");
      setHeadr("Missing data");
      showModal();
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (
      name.length > 0 &&
      email.length > 0 &&
      password.length > 0 &&
      inviteCode.length > 0
    ) {
      // dispatch(loginStart());
      try {
        const res = await axios.post("/auth/signup", {
          name,
          email,
          password,
          inviteCode,
        });
        setMsg("Sign up was successful, continue to sign in!");
        setHeadr("Success");
        //Clear all fields
        setName("");
        setEmail("");
        setPassword("");
        setInviteCode("");
        showModal();
        // dispatch(loginSuccess(res.data));
        // navigate("/")
      } catch (err) {
        // dispatch(loginFailure());
        setMsg("Sign up did not succeed, please try again.");
        setHeadr("Error");
        showModal();
      }
    } else {
      setMsg("Please fill all fields and try again.");
      setHeadr("Missing data");
      showModal();
    }
  };

  const signInWithGoogle = async () => {
    dispatch(loginStart());
    signInWithPopup(auth, provider)
      .then((result) => {
        axios
          .post("/auth/google", {
            name: result.user.displayName,
            email: result.user.email,
            img: result.user.photoURL,
          })
          .then((res) => {
            console.log(res);
            dispatch(loginSuccess(res.data));
            navigate("/");
          });
      })
      .catch((error) => {
        dispatch(loginFailure());
      });
  };

  //TODO: REGISTER FUNCTIONALITY

  return (
    <Container>
      <Wrapper>
        <Modal
          title={headr}
          open={isModalOpen}
          onOk={handleOk}
          footer={[
            <Button key="back" onClick={handleOk}>
              Ok
            </Button>,
          ]}
        >
          <p>{msg}</p>
        </Modal>

        <Title>Sign in</Title>
        <SubTitle>to continue to Amleko</SubTitle>
        <Input
          placeholder="username"
          onChange={(e) => {
            // console.log(e.target.value.toLowerCase());
            setName(e.target.value.toLowerCase());
          }}
        />
        <Input
          type="password"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={handleLogin}>Sign in</Button>
        {/* <Title>or</Title> */}
        {/* <Button onClick={signInWithGoogle}>Signin with Google</Button> */}
        <Title></Title>
        <Title>or Sign up</Title>

        <Input
          placeholder="username"
          onChange={(e) => {
            // console.log(e.target.value.toLowerCase());
            setName(e.target.value.toLowerCase());
          }}
        />
        <Input placeholder="email" onChange={(e) => setEmail(e.target.value)} />
        <Input
          type="password"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          type="name"
          placeholder="invitation code"
          onChange={(e) => setInviteCode(e.target.value)}
        />
        <Button onClick={handleSignUp}>Sign up</Button>
      </Wrapper>
      <More>
        Amleko © 2023 All rights reserved.
        {/* <Links>
          <Link>Help</Link>
          <Link>Privacy</Link>
          <Link>Terms</Link>
        </Links> */}
      </More>
    </Container>
  );
};

export default SignIn;
