import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Comment from "./Comment";

const Container = styled.div`
  margin-top: 20px;
`;

const NewComment = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const Avatar = styled.img`
  background-color: black;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  background-color: transparent;
  outline: none;
  padding: 5px;
  width: 100%;
  font-size: 16px;
`;

function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}

function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
}

const Comments = ({ videoId }) => {
  const { currentUser } = useSelector((state) => state.user);

  const [comments, setComments] = useState([]);
  const [desc, setDesc] = useState("");
  const [img, setImg] = useState("");
  const [time, setTime] = useState(Date.now());
  const [count, setCount] = useState(0);

  const isVisible = usePageVisibility();
  const delay = 5000;

  useEffect(() => {
    const fetchChannel = async () => {
      const res = await axios.get(`/users/find/${currentUser._id}`);
      setImg(res.data.img);
    };
    fetchChannel();
  }, [currentUser._id]);

  const fetchComments = async () => {
    try {
      const res = await axios.get(`/comments/${videoId}`);
      setComments(res.data);
      // console.log("COMMENTS: ", comments);
    } catch (err) {}
  };

  useEffect(() => {
    fetchComments();
  }, [videoId]);

  //USEEFFECT TO REFRESH COMMENTS
  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log("IS VISIBLE: ", isVisible);
      if (isVisible) {
        console.log("Refreshing now.");
        fetchComments();
        setCount(count + 1);
      } else {
        setCount(count + 1);
      }
    }, delay);
    // Remove event listener on cleanup
    return () => clearTimeout(timeout);
  }, [count]);

  const SECONDS_MS = 5000;

  // useEffect(() => {
  //   if (!document.hidden) {
  //     const interval = setInterval(() => {
  //       console.log("Logs every 5 seconds");
  //     }, SECONDS_MS);
  //     return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  //   }
  // }, []);

  //TODO: ADD NEW COMMENT FUNCTIONALITY
  const handleNewComment = async () => {
    if (desc.length > 0) {
      console.log(desc);
      var obj = {
        userId: currentUser._id,
        user: currentUser,
        videoId: videoId,
        desc: desc,
      };
      const copyArr = comments;
      try {
        const res = await axios.post(`/comments/`, obj);
        console.log("RESPONSE FROM COMMENT ADD: ", res);
        // var newArr = copyArr.push(res.data);
        // setComments(newArr);
        setDesc("");
        const resB = await axios.get(`/comments/${videoId}`);
        setComments(resB.data);
        console.log("COMMENTS: ", comments);
      } catch (error) {
        console.log("ERROR on Comment Add: ", error);
      }
    }
  };

  return (
    <Container>
      <NewComment>
        <Avatar src={img} />
        <Input
          onChange={(event) => {
            console.log(event.target.value);
            setDesc(event.target.value);
            // this.setState({ query: event.target.value });
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              handleNewComment();
            }
          }}
          placeholder="Add a comment..."
          value={desc}
        />
      </NewComment>
      {comments.map((comment) => (
        <Comment key={comment._id} comment={comment} />
      ))}
    </Container>
  );
};

export default Comments;
