import { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Menu from "./components/Menu";
import Navbar from "./components/Navbar";
import { darkTheme, lightTheme } from "./utils/Theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Video from "./pages/Video";
import SignIn from "./pages/SignIn";
import Search from "./pages/Search";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const Main = styled.div`
  flex: 7;
  background-color: ${({ theme }) => theme.bg};
  @media screen and (max-width: 768px) {
  }
`;
const Wrapper = styled.div`
  padding: 22px 66px;
  @media screen and (max-width: 768px) {
    padding: 22px 26px;
  }
`;

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const { currentUser } = useSelector((state) => state.user);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Container>
        <BrowserRouter>
          {currentUser ? (
            <>
              <Menu darkMode={darkMode} setDarkMode={setDarkMode} />
              <Main>
                <Navbar />
                <Wrapper>
                  <Routes>
                    <Route path="/">
                      <Route index element={<Home type="random" />} />
                      <Route path="trends" element={<Home type="trend" />} />
                      <Route
                        path="subscriptions"
                        element={<Home type="sub" />}
                      />
                      <Route path="search" element={<Search />} />
                      <Route
                        path="signin"
                        element={currentUser ? <Home /> : <SignIn />}
                      />
                      <Route path="video">
                        <Route path=":id" element={<Video />} />
                      </Route>
                    </Route>
                  </Routes>
                </Wrapper>
              </Main>
            </>
          ) : (
            <>
              <Main>
                <SignIn />
              </Main>
            </>
          )}
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  );
}

export default App;
