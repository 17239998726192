export const darkTheme = {
  bg: "#181818",
  bgLighter: "#202020",
  text: "white",
  textSoft: "#aaaaaa",
  soft: "#373737",
};
export const lightTheme = {
  bg: "#f9f9f9",
  bgLighter: "white",
  text: "black",
  textSoft: "#606060",
  soft: "#f5f5f5",
};

// export const darkTheme = {
//   bg: "#11151c",
//   bgLighter: "#2e1f27",
//   text: "white",
//   textSoft: "#aaaaaa",
//   soft: "#373737",
// };
// export const lightTheme = {
//   bg: "#f9f9f9",
//   // bgLighter: "#f8f7f9",
//   bgLighter: "#feefe5",
//   text: "black",
//   textSoft: "#606060",
//   soft: "#f5f5f5",
// };
