import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../components/Card";
import axios from "axios";

const Container = styled.div`
  display: flex;
  // justify-content: space-between
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const Details = styled.div`
  display: flex;
  font-size: 33px;
  font-weight: 500;
  margin: auto;

  padding-top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: ${({ theme }) => theme.text};
`;
const Caption = styled.div`
  font-size: 18px;
  font-weight: 300;

  color: ${({ theme }) => theme.text};
`;

const Home = ({ type }) => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const res = await axios.get(`/videos/${type}`);
      setVideos(res.data);
    };
    fetchVideos();
  }, [type]);

  return (
    <Container>
      {videos.map((video) => (
        <Card key={video._id} video={video} />
      ))}
      {/* {videos.length > 0 ? (
        <>
          {videos.map((video) => (
            <Card key={video._id} video={video} />
          ))}
        </>
      ) : (
        <>
          <Details>No Videos Available</Details>
        </>
      )} */}
    </Container>
  );
};

export default Home;
