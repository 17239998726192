import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAgvvNXPtyOsHzctREdo9_KqFO5wwIUj2c",
  authDomain: "test-93d6a.firebaseapp.com",
  databaseURL: "https://test-93d6a.firebaseio.com",
  projectId: "test-93d6a",
  storageBucket: "test-93d6a.appspot.com",
  messagingSenderId: "450173761869",
  appId: "1:450173761869:web:57c73524f40c454f835f04",
  measurementId: "G-R9Y8DN151F",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const provider = new GoogleAuthProvider();

export default app;
